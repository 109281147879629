.left-panel {
  flex: 3;
  border-right: 1px solid #ddd;
  padding-right: 16px;
  overflow-x: auto; /* Habilita scroll horizontal */
  white-space: nowrap; /* Asegura que los elementos no se envuelvan */
}

.ticket-container-horizontal {
  display: inline-flex; /* Apila los tickets horizontalmente */
  gap: 16px; /* Espaciado entre los tickets */
}

.ticket-card {
  display: inline-block; /* Asegura que los tickets sean individuales */
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  min-width: 200px; /* Ancho mínimo para que todos tengan el mismo tamaño */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.ticket-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
}

.ticket-card p {
  font-size: 14px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 8px;
}

.modal-content-container {
  display: flex;
  gap: 16px;
  width: 100%;
}

.left-panel {
  flex: 3;
  border-right: 1px solid #ddd;
  padding-right: 16px;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.ticket-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.ticket-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.ticket-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
}

.ticket-card p {
  font-size: 14px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 8px;
}

.ticket-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
}

.ticket-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 1100px;
  max-height: 90%;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  font-size: 14px;
  line-height: 1.6;
}
