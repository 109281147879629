.react-day-picker {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.react-day-picker-day {
  font-size: 14px;
  color: #333;
}

.react-day-picker-day--selected {
  background-color: #007BFF;
  color: white;
}

.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
}

.filter-input,
.filter-select,
.date-filter input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.date-filter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  font-size: 1.2rem;
  color: #666;
}

.filter-button,
.export-button,
.new-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filter-button:hover,
.export-button:hover,
.new-button:hover {
  background-color: #0056b3;
}

.tickets-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.tickets-table thead {
  background-color: #f4f4f4;
}

.tickets-table th,
.tickets-table td {
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  padding: 0.75rem;
}

.tickets-table th {
  font-weight: bold;
  color: #333;
  position: relative;
  cursor: pointer;
}

.tickets-table th:hover {
  background-color: #e9ecef;
}

.tickets-table th.sortable::after {
  content: " ";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-top-color: #6c757d;
}

.tickets-table th.sortable.asc::after {
  border-top-color: transparent;
  border-bottom-color: #6c757d;
}

.tickets-table tbody tr:hover {
  background-color: #f9f9f9;
}

.status-badge {
  padding: 0.3rem 0.6rem;
  border-radius: 12px;
  font-size: 0.9rem;
  color: white;
  font-weight: bold;
}

  .status-badge.creado {
    background-color: #6c757d;
  }
  
  .status-badge.enproceso {
    background-color: #fff12f;
  }
  
  .status-badge.asignado {
    background-color: #757575;
  }
  
  .status-badge.pendiente {
    background-color: #757575;
  }

  .status-badge.finalizado {
    background-color: #11c02e;
  }

  .status-badge.cancelado {
    background-color: #c71010;
  }
  